const re = /^[a-zA-Z0-9.]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

/**
 *
 * @param email
 * @returns {boolean}
 */
const isEmail = email => {
  if (!email || typeof email !== "string") return false;
  return re.test(email);
};

export default isEmail;
