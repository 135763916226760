import React, { PureComponent } from "react";
import * as PropTypes from "prop-types";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { MapCard, H2 } from "cf-neo-ui";
import CFConfigs from "../../../configs/club-freelance-globals";
import classes from "./AddressMaps.module.scss";

class AddressMaps extends PureComponent {
  onClickMap = country => {
    const { address } = CFConfigs;
    if (country === "london")
      window.open(`https://g.page/Mindquest-Talent?share`);
    else window.open(`https://goo.gl/maps/${address[country]}`);
  };

  render() {
    const { i18n } = this.props;
    const { barcelone, london, paris } = CFConfigs.locations;
    return (
      <div className={classes.addressMaps}>
        <h2 className={classes.heading}>
          <Trans>Nos bureaux</Trans>
        </h2>
        <div className={classes.mapContainer}>
          <MapCard
            image="/assets/images/maps/MQLondres.PNG"
            city={i18n._(t`Londres`)}
            //sub1={i18n._(t`Mindquest (Club Freelance Limited)`)}
            sub1={
              <div className={classes.companyName}>
                Mindquest
                <div className={classes.gray}>(Club Freelance Limited)</div>
              </div>
            }
            sub2={london.streetAddress}
            sub3={london.postalCode + " " + london.addressRegion}
            onClickMap={() => this.onClickMap("london")}
            className={classes.mapCard}
          />
          <MapCard
            image="/assets/images/maps/MQParis.PNG"
            city={i18n._(t`Paris`)}
            // sub1={i18n._(t`Mindquest (Club Freelance France) `)}
            sub1={
              <div className={classes.companyName}>
                Mindquest
                <div className={classes.gray}>(Club Freelance France) </div>
              </div>
            }
            sub2={paris.streetAddress}
            sub3={paris.postalCode + " " + paris.addressRegion}
            onClickMap={() => this.onClickMap("paris")}
            className={classes.mapCard}
          />
          <MapCard
            image="/assets/images/maps/MQBarcelona.PNG"
            city={i18n._(t`Barcelone`)}
            //sub1={i18n._(t`Mindquest (Club Freelance Iberica)`)}
            sub1={
              <div className={classes.companyName}>
                Mindquest
                <div className={classes.gray}>
                  (<trans>Club Freelance Iberica</trans>)
                </div>
              </div>
            }
            sub2={barcelone.streetAddress}
            sub3={barcelone.postalCode}
            onClickMap={() => this.onClickMap("barcelone")}
            className={classes.mapCard}
          />
        </div>
      </div>
    );
  }
}

AddressMaps.propTypes = {
  i18n: PropTypes.shape({
    _: PropTypes.func
  }).isRequired
};

export default withI18n()(AddressMaps);
