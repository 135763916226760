import React, { Component } from "react";
import * as PropTypes from "prop-types";
import { Modal, ThemeProvider, toaster } from "cf-neo-ui";
import { I18nProvider } from "@lingui/react";
import { inject, observer } from "mobx-react";
import { Mutation } from "react-apollo";
import catalogEn from "./locales/en/messages";
import catalogFr from "./locales/fr/messages";
import theme from "./configs/theme";
import "./App.scss";
import ServerTest from "./components/ServerTest/ServerTest";
import NotFoundPage from "./components/error-pages/NotFoundPage";
import PrivacyAndPolicyUpdateModal from "./components/PrivacyAndPolicyUpdate-modal/PrivacyAndPolicyUpdateModal";
import { ACCEPT_PRIVACY_AND_POLICY } from "./components/PrivacyAndPolicyUpdate-modal/utils/mutations/mutations";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const catalogs = {
  en: catalogEn,
  fr: catalogFr
};

@inject("appStore", "sessionStore")
@observer
class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loadingContent: true, isVisible: true };
  }

  componentDidMount() {
    this.setState({ loadingContent: false });
  }

  mutationCompleted() {
    window.location.reload();
  }

  render() {
    const { loadingContent } = this.state;
    const { forcedError, appStore, sessionStore } = this.props;
    const authToken = sessionStore.authToken;
    let accountEmail = "";
    let privacyAndPolicy = true;
    let isReading = false;
   
    
    if (typeof window !== "undefined") {
     console.log("URLLLLLL : ",window.location.path ) ;
      if (
        window.location.pathname === "/privacy-policy" ||
        window.location.pathname === "/terms-and-conditions-of-use"
      ) {
        isReading = true;
      }
    }
    if (authToken) {
      if (sessionStore) {
        if (sessionStore.account) {
          if (sessionStore.account.email) {
            accountEmail = sessionStore.account.email;
          }
          if (!sessionStore.account.privacyAndPolicyAgreement) {
            privacyAndPolicy = sessionStore.account.privacyAndPolicyAgreement;
          }
        }
      }
    }
    const { currentLanguage } = appStore;
    const { isVisible } = this.state;
    return (
      <div>
        {loadingContent && (
          <div className="center-loading_logo">
            <div className="lds-ripple">
              <div />
              <div />
            </div>
            <picture>
              <source
                type="images/webp"
                srcSet="/assets/icons/android/icon-96.webp"
              />
              <img
                src="/assets/icons/android/icon-96.webp"
                alt="Mindquest icon"
                className="loading_logo"
              />
            </picture>
          </div>
        )}
        <div
          style={{
            visibility: loadingContent ? "hidden" : "visible"
          }}
        >
          <ThemeProvider theme={theme}>
            <I18nProvider language={currentLanguage} catalogs={catalogs}>
              {forcedError ? (
                <NotFoundPage message={forcedError.toString()} />
              ) : (
                <ServerTest />
              )}
              {authToken && !privacyAndPolicy && !isReading && (
                <Modal
                  centred
                  isVisible={isVisible}
                  withCloseButton={false}
                  position="fixed"
                >
                  <Mutation
                    mutation={ACCEPT_PRIVACY_AND_POLICY}
                    variables={{
                      accountEmail
                    }}
                    onCompleted={this.mutationCompleted}
                    onError={errors => {
                      errors.graphQLErrors.forEach(({ message }) => {
                        if (message) {
                          this.onErrorHandler(message);
                        }
                      });
                    }}
                  >
                    {acceptPrivacyAndPolicy => (
                      <PrivacyAndPolicyUpdateModal
                        onSubmit={() => {
                          acceptPrivacyAndPolicy();
                        }}
                      />
                    )}
                  </Mutation>
                </Modal>
              )}
            </I18nProvider>
          </ThemeProvider>
        </div>
      </div>
    );
  }
}

App.wrappedComponent.propTypes = {
  forcedError: PropTypes.shape({}),
  appStore: PropTypes.shape({
    currentLanguage: PropTypes.string
  }).isRequired
};

App.defaultProps = {
  forcedError: null
};

export default App;
